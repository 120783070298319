<template>
  <div>
    <crud-button
      :onClickList="fetchBanners"   
      :onClickAddNew="addNew"
    />
    <b-card no-body class="mb-0">
      <b-table
        class="position-relative"
        :items="getBanners"
        responsive
        :fields="columns"
        primary-key="id"
        show-empty
        empty-text="Kayıt bulunamadı"
      >
        <template #cell(sDilKodu)="data">
          {{ data.item.sDilKodu == 'tr' ? 'Türkçe' : 'English' }}
        </template>
       
        <!-- Column: User -->
        <template #cell(isActive)="data">
          <b-form-checkbox
            :checked="data.item.isActive"
            class="custom-control-success"
            name="check-button"
            @change="deactivate(data.item.id)"
          >
            {{ $t('Aktif/Pasif') }}
          </b-form-checkbox>
        </template>
        <template #cell(actions)="data">
          <!-- Update -->
          <b-button
            size="sm"
            @click="
              $router.push({
                name: 'manage-website-banner-save',
                params: { id: data.item.id },
              })
            "
            variant="primary"
          >
            <span class="align-middle">{{ $t('Güncelle') }}</span>
          </b-button>

          <!-- Remove -->
          <b-button
            size="sm"
            class="ml-1 remove-button-mt-1"
            @click="remove(data.item.id)"
            variant="warning"
          >
            <span class="align-middle">{{ $t('Kaldır') }}</span>
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  data() {
    return {
      columns: [
        { key: 'sBaslik', label: 'Başlık' },
        { key: 'sDilKodu', label: 'Dil Kodu' },
        { key: 'ldSira', label: 'Sıra', sortable: true },
        { key: 'isActive', label: 'Aktif Durum', tdStyle: 'width:5px !important', thStyle: 'width:5px !important' },
        { key: 'actions', label: 'Aksiyon', class: 'text-right' },
      ],
    }
  },
  created() {
    this.fetchBanners();
  },

  computed: {
    getBanners() {
      return this.$store.getters['bannerYonetim/getBanners']
    },
  },

  methods: {
    fetchBanners() {
      this.$store.dispatch('bannerYonetim/fetchBanners', {})
    },

    addNew() {
      this.$router.push({ name: 'manage-website-banner-save', params: { id: -1 } })
    },

    remove(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('bannerYonetim/removeBanner', id)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })

              this.fetchBanners();
            } else {
              AlertService.error(this, response.message)
            }
          });
      });
    },

    deactivate(id) {
      this.$store.dispatch('bannerYonetim/toggleActiveBanner', id)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
          } else {
            AlertService.error(this, response.message);
          }
        })
    },
  },
}
</script>

<style scoped>
@media (max-width: 890px) {
  .remove-button-mt-1 {
    margin-top: 10px !important;
  }
}
</style>
